window.wHTML = (function($){

    /* Приватные переменные */

    var varSeoIframe = 'seoIframe',
        varSeoTxt = 'seoTxt',
        varSeoClone = 'cloneSeo',
        varSeoDelay = 250;

    /* Приватные функции */

    /* проверка типа данных на объект */
    var _isObject = function(data) {
            var flag = (typeof data == 'object') && (data+'' != 'null');
            return flag;
        },

        /* создание нового элемента элемента */
        _crtEl = function(tag, classes, attrs, jq) {
            var tagName = tag || 'div';
            var element = document.createElement(tagName);
            var jQueryElement = jq || true;
            // если классы объявлены - добавляем
            if (classes) {
                var tagClasses = classes.split(' ');
                for (var i = 0; i < tagClasses.length; i++) {
                    element.classList.add(tagClasses[i]);
                }
            }
            // если атрибуты объявлены - добавляем
            if (_isObject(attrs)) {
                for (var key in attrs) {
                    var val = attrs[key];
                    element[key] = val;
                }
            }
            // возвращаем созданый елемент
            if (jQueryElement) {
                return $(element);
            } else {
                return element;
            }
        },

        /* создаем iframe для сео текста */
        _seoBuild = function(wrapper) {
            var seoTimer;
            // создаем iframe, который будет следить за resize'm окна
            var iframe = _crtEl('iframe', false, {id: varSeoIframe, name: varSeoIframe});
            iframe.css({
                'position':'absolute',
                'left':'0',
                'top':'0',
                'width':'100%',
                'height':'100%',
                'z-index':'-1'
            });
            // добавляем его в родитель сео текста
            wrapper.prepend(iframe);
            // "прослушка" ресайза
            seoIframe.onresize = function() {
                clearTimeout(seoTimer);
                seoTimer = setTimeout(function() {
                    wHTML.seoSet();
                }, varSeoDelay);
            };
            // вызываем seoSet()
            wHTML.seoSet();
        };

    /* Публичные методы */

    function Methods(){}

    Methods.prototype = {

        /* установка cео текста на странице */
        seoSet: function() {
            if ($('#'+varSeoTxt).length) {
                var seoText = $('#'+varSeoTxt);
                var iframe = seoText.children('#'+varSeoIframe);
                if (iframe.length) {
                    // если iframe сущствует устанавливаем на место сео текст
                    var seoClone = $('#'+varSeoClone);
                    if (seoClone.length) {
                        // клонеру задаем высоту
                        seoClone.height(seoText.outerHeight(true));
                        // тексту задаем позицию
                        seoText.css({
                            top: seoClone.offset().top,
                            left: seoClone.offset().left,
                            width: seoClone.width()
                        });
                    } else {
                        // клонера нету - бьем в колокола !!!
                        console.error('"'+varSeoClone+'" - не найден!');
                    }
                } else {
                    // если iframe отсутствует, создаем его и устанавливаем на место сео текст
                    _seoBuild(seoText);
                }
            }
        },

        /* magnificPopup inline */
        mfi: function() {
            $('.mfi').magnificPopup({
                type: 'inline',
                closeBtnInside: true,
                removalDelay: 300,
                mainClass: 'zoom-in'
            });
        },

        /* magnificPopup ajax */
        mfiAjax: function() {
            $('body').magnificPopup({
                delegate: '.mfiA',
                callbacks: {
                    elementParse: function(item) {
                        this.st.ajax.settings = {
                            url: item.el.data('url'),
                            type: 'POST',
                            data: (typeof item.el.data('param') !== 'undefined') ? item.el.data('param') : ''
                        };
                    },
                    ajaxContentAdded: function(el) {

                        wHTML.validation();
                        $('.phoneMask').inputmask({"mask": "+380999999999"});
                    }
                },
                type: 'ajax',
                removalDelay: 300,
                mainClass: 'zoom-in'
            });
        },

        /* оборачивание iframe и video для адаптации */
        wTxtIFRAME: function() {
            var list = $('.wTxt').find('iframe').add($('.wTxt').find('video'));
            if (list.length) {
                // в цикле для каждого
                for (var i = 0; i < list.length; i++) {
                    var element = list[i];
                    var jqElement = $(element);
                    // если имеет класс ignoreHolder, пропускаем
                    if (jqElement.hasClass('ignoreHolder')) {
                        continue;
                    }
                    if (typeof jqElement.data('wraped') === 'undefined') {
                        // определяем соотношение сторон
                        var ratio = parseFloat((+element.offsetHeight / +element.offsetWidth * 100).toFixed(2));
                        if (isNaN(ratio)) {
                            // страховка 16:9
                            ratio = 56.25;
                        }
                        // назнчаем дату и обрачиваем блоком
                        jqElement.data('wraped', true).wrap('<div class="iframeHolder ratio_' + ratio.toFixed(0) + '" style="padding-top:'+ratio+'%;""></div>');
                    }
                }
                // фиксим сео текст
                this.seoSet();
            }
        }
    };

    /* Объявление wHTML и базовые свойства */

    var wHTML = $.extend(true, Methods.prototype, {});

    return wHTML;

})(jQuery);

jQuery(document).ready(function(){

    wHTML.mfi();
    wHTML.seoSet();
    // magnificPopup ajax
    wHTML.mfiAjax();
    // валидация форм
    wHTML.validation();

    $("#mobileMenu").mmenu({
        "extensions": [
            "effect-menu-slide",
            "effect-panels-zoom"
        ],
        navbar: {
            title: "Меню"
        }
    });

    $('.mainSlider').owlCarousel({
        loop:true,
        margin:0,
        dots:true,
        items:1
    });

    $('.smallItemsSlider').owlCarousel({
        loop:true,
        margin:4,
        responsiveClass:true,
        nav:true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            600:{
                items:2,
                nav:true
            },
            860:{
                items:3,
                nav:true
            },
            1100:{
                items:4,
                nav:true
            },
            1241:{
                items:3,
                nav:true
            }
        }
    });

    var owl = $('.oneItem__smallSlider').owlCarousel({
        loop:true,
        margin:10,
        responsiveClass:true,
        mouseDrag:false,
        nav:true,
        responsive:{
            0:{
                items:2,
                nav:true
            },
            400:{
                items:3,
                nav:true
            },
            480:{
                items:3,
                nav:true
            },
            520:{
                items:4,
                nav:true
            },
            620:{
                items:5,
                nav:true
            },
            800:{
                items:3,
                nav:true
            },
            960:{
                items:4,
                nav:true
            },
            1240:{
                items:4,
                nav:true
            }
        }
    });

    $(".oneItem__smallSlider").on("click", '.js-click', function(){
        var el = $(this);
        el.closest(".owl-stage").find('.is-active').removeClass("is-active");
        el.addClass('is-active');

        var src = el.find('img').attr("src");
        $('.oneItem__bigPic img').attr("src",src);
    });

    $('.phoneMask').inputmask({"mask": "+380999999999"});

    /**
     * функция склонения слова "товар"
     * @param num
     * @param str1
     * @param str2
     * @param str3
     * @returns {*}
     */
    function get_correct_str(num, str1, str2, str3) {
        var val;
        val = num % 100;

        if (val > 10 && val < 20)
        {
            return str3;
        }
        else {
            val = num % 10;
            if (val == 1) {
                return str1;
            }
            else {
                if (val > 1 && val < 5) {
                    return str2;
                } else return str3;
            }

        }
    }

    Handlebars.registerHelper('ifIsset', function(p1,options) {
        if(p1 === "Есть в наличии") {
            return options.fn(this);
        }
        return options.inverse(this);
    });
    Handlebars.registerHelper("math", function(lvalue, operator, rvalue, options) {
        lvalue = parseFloat(lvalue);
        rvalue = parseFloat(rvalue);

        return {
            "+": lvalue + rvalue,
            "-": lvalue - rvalue,
            "*": lvalue * rvalue,
            "/": lvalue / rvalue,
            "%": lvalue % rvalue
        }[operator];
    });
    Handlebars.registerHelper('select', function( value, options ){
        var $el = $('<select />').html( options.fn(this) );
        $el.find('[value="' + value + '"]').attr({'selected':'selected'});
        return $el.html();
    });

    Handlebars.registerHelper('ifnew', function(p1,options) {
        if(p1 === "Новинка") {
            return "new";
        }
    });
    Handlebars.registerHelper('iftop', function(p1,options) {
        if(p1 === "Топ продаж") {
            return "top";
        }
    });
    Handlebars.registerHelper('ifpromo', function(p1,options) {
        if(p1 === "Акция") {
            return "promo";
        }
    });

    Handlebars.registerHelper('ifprice', function(p1,options) {
        if(p1 === "Скидка") {
            return "price";
        }
    });

    $('.js-time').on("click",function(){
        $(".mobMenu__info").stop().fadeToggle();
    });

    /**
     * проверяем какой таб с активным классом и показываем его
     */
    function check_ajax_tabs(){
        $('.js-blocks > div').addClass("first");
    }
    check_ajax_tabs();

    $('.js-more').on("click",function(){
        var el = $(this);
        var offset = el.data("offset");
        var active_tab = $('.js-tabs').find('.is-active').data('block');

        $('.smallItems[data-src="'+active_tab+'"] .smallItems__inner').append("<div class='overlay'></div>");
        $('.smallItems[data-src="'+active_tab+'"] .smallItems__inner').find('.overlay').addClass("anim");

        $.ajax({
            method: "POST",
            url: "hidden/item.php",
            data: { cat: active_tab, offset: offset },
            dataType: "json",
            success:function(data){
                template = Handlebars.compile( $('#smallItem').html() );
                $('.smallItems[data-src="'+active_tab+'"] .smallItems__inner').append(template(data));
                $('.smallItems[data-src="'+active_tab+'"] .smallItems__inner').find(".overlay").fadeOut(400,function(){
                    $('.smallItems[data-src="'+active_tab+'"] .smallItems__inner').find(".overlay").remove(".overlay");
                });
            }
        }).done(function(){
            wHTML.seoSet();
            $('.smallItem').matchHeight();
        });

    });

    /**
     * клик по пункту аякс таба,
     */
    $('.js-tabs span').on('click',function(){
        var el = $(this);
        var active_tab = $('.js-tabs').find('.is-active').data('block');
        var need = el.parent().data('block');
        var first = $('.js-blocks').find('[data-src="'+need+'"]').hasClass("first");

        if((active_tab != need) || first) {
            $('.js-blocks').find('[data-src="'+active_tab+'"]').fadeOut(400,function(){
                $('.js-tabs').find('.is-active').removeClass('is-active');
                $('.js-blocks').find('[data-src="'+need+'"]').removeClass("first");

                if(first) {
                    $('.smallItems[data-src="'+active_tab+'"] .smallItems__inner').append("<div class='overlay'></div>");
                    $('.smallItems[data-src="'+active_tab+'"] .smallItems__inner').find('.overlay').addClass("anim");
                    //парсим джейсон и вставляем товары в таб, можно манипулировать active_tab, что бы знать какую группу товаров грузить offset=0
                    $.ajax({
                        method: "POST",
                        url: "hidden/item.php",
                        data: { cat: need },
                        dataType: "json",
                        success:function(data){
                            template = Handlebars.compile( $('#smallItem').html() );
                            $('.smallItems[data-src="'+need+'"] .smallItems__inner').append(template(data));
                            $('.smallItems[data-src="'+active_tab+'"] .smallItems__inner').find(".overlay").fadeOut(400,function(){
                                $('.smallItems[data-src="'+active_tab+'"] .smallItems__inner').find(".overlay").remove(".overlay");
                            });
                        }
                    }).done(function(){
                        wHTML.seoSet();
                        $('.smallItem').matchHeight();
                    });
                }

                $('.js-blocks').find('[data-src="'+need+'"]').fadeIn(400,function(){
                    wHTML.seoSet();
                });
                el.parent().addClass('is-active');
            });
        }
    });

    /**
     * клик по пункту НЕ аякс таба,
     */
    $('.js-Ntabs span').on('click',function(){
        var el = $(this);
        var ntabs = el.closest(".js-Ntabs");
        var active_tab = ntabs.find('.is-active').data('block');
        var need = el.parent().data('block');

        if(active_tab != need) {
            $('.js-blocks').find('[data-src="'+active_tab+'"]').fadeOut(400,function(){
                ntabs.find('.is-active').removeClass('is-active');
                $('.js-blocks').find('[data-src="'+need+'"]').fadeIn(400,function(){
                    wHTML.seoSet();
                });
                el.parent().addClass('is-active');
            });
        }
    });

    /**
     * создание пустого массива для объектов
     */
    var basket = {
        items: []
    };

    /**
     * функция перерисовки корзины, при каждом открытии корзины во всплывайке или на странице оформления идет аякс с целью проверки изменения цен,
     * если вдруг данные в локал сторадж устарели, затем идет обновление цен и перерисовка товаров.
     * Можно записывать в локалсторажд еще дату и время корзины, что бы посылать аякс не каждый раз при открытии, а еще проверять условие на кол-во прошедшего времени
     * и вызывать аякс например если данные корзины устарели более чем на 30 минут, 1 день и т.д
     */
    function redrawBasket(){
        var ids = [];
        var length = basket.items.length;

        for(var i=0; i<length;i++) ids.push(basket.items[i].id);

        $.ajax({
            method: "POST",
            url: "hidden/checkPrice.php",
            data: { ids: ids },
            dataType: "json",
            success:function(data){
            var il = data.items.length;
                for(i=0;i<il;i++) {
                    //если товар остался в наличии то обновляем цену, если товара нет в наличии - удаляем из корзины.
                    var item = _.findWhere(basket.items,{id: data.items[i].id});
                    if(data.items[i].isset == "Есть в наличии") {
                        item.price = data.items[i].price;
                    } else{
                        basket.items = _.without(basket.items, _.findWhere(basket.items, {
                            id: data.items[i].id
                        }));
                    }
                }

                var res = JSON.stringify(basket);
                localStorage.setItem('basket', res);

                var template = Handlebars.compile( $("#Pbasket").html() );
                $('.basketPopup__inner').html(template(basket));

                total();
                recount();
                if($('.basketPage').length) {
                    redrawMainBasket();
                }
            }
        });

    }
    function redrawMainBasket(){
        if($('.middle').find('.basketPage').length) {
            var template = Handlebars.compile($("#basketT").html());
            $('.basketPage__inner').html(template(basket));
            total2();
        }
    }

    /**
     * если есть джейсон в локалсторадж - грузим в шаблонизатор данные
     * можно грузить данные с сервера, или делать аякс помещая ответ в локалстарадж и вызываю эту функцию, отрисовка только во время вызова попапа корзины
     */
    function loadFromLocalStorage(){
        var jsonLocal = JSON.parse(localStorage.getItem("basket"));
        if(jsonLocal != null) {
            basket = jsonLocal;
        }
    }
    loadFromLocalStorage();

    /**
     * функция пересчета кол-во товаров в корзине и словоформа слова "товар"
     */
    function recount(){
        var totalCount = parseInt(basket.items.length);
        $('.logoLine__infoBasket__count').text(totalCount);
        $('.mobMenu__basket b').text(totalCount);
        var txt = get_correct_str(totalCount,"товар","товара","товаров");
        $('.logoLine__infoBasket__text').text(txt);
    }
    recount();

    function total(){
        var total = 0;
        $.each($(".basketPopup__priceCount"),function(){
            total += parseInt($(this).text());
        });
        $('.js-total > span').text(total);
    }
    function total2(){
        var total = 0;
        $.each($(".basketPage__basket__pCount"),function(){
            total += parseInt($(this).text());
        });
        $('.js-total > span').text(total);
    }

    if($('.basketPage').length) {
        redrawBasket();
    }

    /**
     * клик по кнопке купить
     * создание объекта в basket.items, или модификация если объект существует
     * @id - (int) айди товара
     * @name - (string) Название товара
     * @code - (int) код товара
     * @price - (int) цена товара
     * @image - (string) ссылка на картинку
     * @count - (int) кол-во товаров
     *
     */
    $('body').on('click', '.js-add',function(){
        $('.js-close-basket').trigger("click");
        var item = {};
        var smallItem = !$(this).hasClass('js-add-item');

        var parent = ".oneItem";
        var parentEl = $(this).closest(parent);
        var image = "";
        var isset = false;

        if(smallItem) {
            parent = ".smallItem";
            parentEl = $(this).closest(parent);
            image = parentEl.find(".smallItem__image img").attr("src");
            isset = parentEl.find('.smallItem__isset').hasClass("smallItem__isset--true");
        } else {
            image = parentEl.find(".js-mainImg img").attr("src");
            isset = parentEl.find('.oneItem__priceBlock__isset').hasClass("oneItem__priceBlock__isset--true");
        }


        var id = parseInt(parentEl.data('id'));
        var name = parentEl.data('name');
        var code = parseInt(parentEl.data('code'));
        var price = parseInt(parentEl.data('price'));


        if(isset) {
            var count = _.findWhere(basket.items,{id: id});
            item = {
                id:id,
                count:1,
                name:name,
                code:code,
                price:price,
                image:image
            };
            if(count === undefined) {
                basket.items.push(item);
            } else{
                if(count.count < 10) count.count++;
            }

            //var template = Handlebars.compile( $("#Pbasket").html() );
            //$('.basketPopup__inner').html(template(basket));

            var res = JSON.stringify(basket);
            localStorage.setItem('basket', res);

            recount();
        }

    });

    /**
     * клик по кнопке удаления в всплывающей корзине
     * все товары находятся в массиве basket.items
     * товары в виде объектов, манипуляция через айди
     * @id - (int) айди товара
     */
    $('body').on('click','.js-remove',function(){
        var id = parseInt($(this).closest(".-flexWrapper").data("id"));
        var count = _.findWhere(basket.items,{id: id});
        if($(this).closest(".basketPage__basket__item").length) {
            basket.items = _.without(basket.items, _.findWhere(basket.items, {
                id: id
            }));
        } else {
            if(count.count == 1) {
                basket.items = _.without(basket.items, _.findWhere(basket.items, {
                    id: id
                }));
            } else{
                count.count--;
            }
        }
        if(basket.items.length == 0) $('.js-close-basket').trigger("click");

        var template = Handlebars.compile( $("#Pbasket").html() );
        $('.basketPopup__inner').html(template(basket));

        var res = JSON.stringify(basket);
        localStorage.setItem('basket', res);

        recount();
        total();
        redrawMainBasket();
    });

    /**
     * изменение кол-во товаров, на странице оформления в главной корзине
     */
    $('.middle').on("change",".js-basket-select",function(){
        var newCount = parseInt($(this).val());
        var id = parseInt($(this).closest(".-flexWrapper").data("id"));
        var count = _.findWhere(basket.items,{id: id});
        count.count = newCount;

        var res = JSON.stringify(basket);
        localStorage.setItem('basket', res);

        redrawBasket();
        recount();
        total();
    });

    $('.js-open-basket').on('click',function(){
        var basketEl = $('.basketPopup');
        if(!basketEl.is(':visible')) {
            if(basket.items.length != 0) {
                basketEl.addClass('js-show');
                redrawBasket();
                basketEl.stop().fadeIn();
            }
        } else{
            basketEl.removeClass('js-show');
            basketEl.stop().fadeOut();
        }
    });

    $('.js-close-basket').on("click",function(){
        var basket = $('.basketPopup');
        basket.removeClass('js-show');
        basket.stop().fadeOut();
    });

    var open = $('.js-Ntabs').find('.is-active').data("block");
    $('.js-blocks').find('[data-src="'+open+'"]').fadeIn(400,function(){
        wHTML.seoSet();
    });

    $(window).load(function(){
        wHTML.seoSet();
        wHTML.wTxtIFRAME();

        var el = $('.js-tabs').find('.is-active').children("span");
        el.trigger("click");

        if($("#map").length){
            initMap();
        }

        $('.smallItem').matchHeight();
    });

    $(window).resize(function(){
        wHTML.seoSet();
    });

});